/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  width: 96%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 6%;
  margin-left: 6%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 88%;
}
.area > .slim .tiny {
  width: 88%;
}
.cb-layout2 .main,
.cb-layout3 .main {
  width: 100%;
}
.cb-layout2 .main > .unit,
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout3 .main h2,
.cb-layout2 .main .foot,
.cb-layout3 .main .foot {
  margin-right: 0.9202454%;
  margin-left: 0.9202454%;
}
.cb-layout2 .main .part,
.cb-layout3 .main .part,
.cb-layout2 .main > .grid table,
.cb-layout3 .main > .grid table {
  margin-right: 0.9202454%;
  margin-left: 0.9202454%;
  width: 98.1595092%;
}
.cb-layout2 .main .tiny,
.cb-layout3 .main .tiny {
  width: 48.1595092%;
}
.cb-layout2 .main > .slim,
.cb-layout3 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim h2,
.cb-layout3 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout3 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout3 .main > .slim .part,
.cb-layout2 .main > .slim.grid table,
.cb-layout3 .main > .slim.grid table {
  margin-right: 1.8404908%;
  margin-left: 1.8404908%;
}
.cb-layout2 .main > .slim .part,
.cb-layout3 .main > .slim .part,
.cb-layout2 .main > .slim.grid table,
.cb-layout3 .main > .slim.grid table {
  width: 96.3190184%;
}
.cb-layout2 .main > .slim .tiny,
.cb-layout3 .main > .slim .tiny {
  width: 96.3190184%;
}
.cb-layout2 .main > .unit,
.cb-layout3 .main > .unit {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  float: none;
  vertical-align: top;
}
.cb-layout2 .part {
  margin-top: 0.9202454%;
  margin-bottom: 0.9202454%;
}
.area.areaTen {
  width: 100%;
}
.area.areaTen > .unit {
  margin-right: 0.9202454%;
  margin-left: 0.9202454%;
  width: 98.1595092%;
}
.area.areaTen h2,
.area.areaTen .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area.areaTen .part,
.area.areaTen > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.areaTen > .slim {
  width: 48.1595092%;
}
.area.areaTen > .slim h2,
.area.areaTen > .slim .foot,
.area.areaTen > .slim .part,
.area.areaTen > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.area.areaTen > .slim .part,
.area.areaTen > .slim.grid table {
  width: 100%;
}
.area.areaTen > .slim .tiny {
  width: 100%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.side h2,
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part,
.side > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim {
  width: 46%;
}
.side > .slim h2,
.side > .slim .foot,
.side > .slim .part,
.side > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 100%;
}
.side > .slim .tiny {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.base .part,
.base > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.base .tiny {
  width: 46%;
}
.base > .slim {
  width: 33.33333333%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 6%;
  margin-left: 6%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 88%;
}
.base > .slim .tiny {
  width: 88%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.north .part,
.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 100%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest h2,
.farwest .foot {
  margin-right: 1.95567145%;
  margin-left: 1.95567145%;
}
.farwest .part,
.farwest > .grid table {
  margin-right: 1.95567145%;
  margin-left: 1.95567145%;
  width: 96.08865711%;
}
.farwest .tiny {
  width: 46.08865711%;
}
.farwest > .slim {
  width: 50%;
}
.farwest > .slim h2,
.farwest > .slim .foot,
.farwest > .slim .part,
.farwest > .slim.grid table {
  margin-right: 3.91134289%;
  margin-left: 3.91134289%;
}
.farwest > .slim .part,
.farwest > .slim.grid table {
  width: 92.17731421%;
}
.farwest > .slim .tiny {
  width: 92.17731421%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.cb-layout2 .main,
.cb-layout3 .main {
  width: 101.875%;
  margin-left: -0.9375%;
}
div.farwest {
  width: 104.07055631%;
  margin-left: -2.03527815%;
}
.area.areaTen div.unit div.part {
  width: 100%;
  margin: 0;
}
.area.areaTen {
  width: 101.875%;
  margin-left: -0.9375%;
}
.area.areaTen > .unit {
  margin-top: 0.9202454%;
  margin-bottom: 0.9202454%;
}
.side div.unit div.part {
  width: 100%;
  margin: 0;
}
.side div.unit div.head h2 {
  margin: 0;
}
.cb-layout1 .main,
.cb-layout4 .main {
  width: 104%;
  margin-left: -2%;
}
.cb-layout1 .main div.wide:first-child div.part,
.cb-layout4 .main div.wide:first-child div.part {
  margin-left: 1.95567145%;
  margin-right: 1.95567145%;
}
.cb-layout1 .main div.wide:first-child div.part.tall,
.cb-layout4 .main div.wide:first-child div.part.tall {
  width: 96.08865711%;
}
.cb-layout1 .main div.wide:first-child div.part.tiny,
.cb-layout4 .main div.wide:first-child div.part.tiny {
  width: 46.02346806%;
}
.cb-layout1 .main div.wide,
.cb-layout4 .main div.wide {
  width: 66.66666667%;
}
.cb-layout1 .main div.wide div.part,
.cb-layout4 .main div.wide div.part {
  margin-left: 2.93542074%;
  margin-right: 2.93542074%;
}
.cb-layout1 .main div.wide div.part.tall,
.cb-layout4 .main div.wide div.part.tall {
  width: 94.12915851%;
}
.cb-layout1 .main div.wide div.part.tiny,
.cb-layout4 .main div.wide div.part.tiny {
  width: 44.03131115%;
}
.cb-layout1 .main > .wide:first-child {
  margin-top: 40px;
  margin-bottom: -12px;
  width: 100%;
}
.cb-layout4 .main > .wide:first-child {
  width: 100%;
}
#view.cb-layout2 .main .flat div.text p.norm a,
#view.cb-layout3 .main .flat div.text p.norm a,
#view .area.areaTen .flat div.text p.norm a {
  font-size: 28px;
}
#view.cb-layout2 .main .flat div.text p.norm a:first-line,
#view.cb-layout3 .main .flat div.text p.norm a:first-line,
#view .area.areaTen .flat div.text p.norm a:first-line {
  font-size: 46px;
}
.area.areaTen div.unit div.head h2 {
  font-size: 28px;
}
.area.areaTen div.unit div.head h2 strong {
  font-size: 46px;
}
.cb-layout2 .wide:first-child .part:first-child {
  margin-top: 0;
}
.cb-layout2 .loud {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.2;
  max-width: 37em;
}
.cb-layout2 .main .flat,
.cb-layout3 .main .flat {
  margin-left: 0.9202454%;
  margin-right: 0.9202454%;
}
.cb-layout2 .main .flat.wide,
.cb-layout3 .main .flat.wide {
  width: 98.1595092%;
}
.cb-layout2 .main .flat.slim,
.cb-layout3 .main .flat.slim {
  width: 48.1595092%;
}
div.farwest div.seam.wide {
  width: 66.66666667%;
}
div.farwest div.seam.wide .part {
  width: 94.140625%;
  margin-left: 2.9296875%;
  margin-right: 2.9296875%;
}
div.farwest div.seam.slim {
  width: 33.33333333%;
}
div.farwest div.seam.slim .part {
  width: 88.28125%;
  margin-left: 5.859375%;
  margin-right: 5.859375%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.shop,
#disp.cb-shoppingcart {
  left: 20%;
  margin-left: 0;
  width: 60%;
}
/*h1,
h2 {
  .hyphens(none);
}*/
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #af1615;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #af1615;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #811010;
}
.mese .same {
  background: none;
}
/******* module-shop-medium.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 37%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 62%;
}
#disp.shop .zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 51%;
}
#disp.shop .cb-shop-currency > select {
  width: 55%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 62%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-medium.css.map */